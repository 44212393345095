import { createGlobalStyle } from 'styled-components'

const ThemeStyle = createGlobalStyle`
  
:root{
    --font-plain: 'Libre Franklin', sans-serif;
    --font-heading: 'Libre Franklin', sans-serif;
	--font-weight-normal: 400;
	--font-weight-medium: 500;
    --font-weight-bold: 700;
    
    --color-text-dark:rgba(23, 10, 28, 1);
    --color-text-dark-alt:rgba(37, 16, 45, 1);
    
    --color-text-light:#fff;
    --color-text-light-alt:rgba(239, 239, 208, 1);

    --color-background-light-primary:#fff;
	--color-background-light-secondary:rgba(245, 245, 245, 1);
	--color-background-light-tertiary:rgba(244, 232, 193, 1);
    
    --color-background-dark-primary:rgba(23, 10, 28, 1);
    --color-background-darkest-secondary:rgba(12, 5, 15, 1);

    --color-primary:rgba(11, 95, 181, 1);
    --color-primary-background:rgba(11, 95, 181, 1);
    --color-primary-border:rgba(10, 92, 174, 1);
    --color-primary-hover:rgba(10, 92, 174, 1);
    --color-primary-background-hover:rgba(10, 92, 174, 1);
    --color-primary-border-hover:rgba(9, 82, 154, 1);
    --color-primary-background-active:rgba(8, 71, 135, 1);
    --color-primary-border-active:rgba(7, 61, 116, 1);

    --color-secondary:rgba(245, 211, 235, 1);
    --color-secondary-background:rgba(245, 211, 235, 1);
    --color-secondary-border:rgba(244, 205, 232, 1);
  	--color-secondary-hover:rgba(244, 205, 232, 1);
    --color-secondary-background-hover:rgba(240, 188, 225, 1);
    --color-secondary-border-hover:rgba(240, 188, 225, 1);
    --color-secondary-background-active:rgba(236, 172, 218, 1);
    --color-secondary-border-active:rgba(236, 172, 218, 1);
	--color-secondary-opacity:rgba(243, 226, 243, .5);
    
    --color-tertiary:rgba(132, 210, 246, 1);
    --color-tertiary-background:rgba(132, 210, 246, 1);
    --color-tertiary-border:rgba(122, 206, 245, 1);
    --color-tertiary-hover:rgba(103, 199, 244, 1);
    --color-tertiary-background-hover:rgba(83, 192, 243, 1);
    --color-tertiary-border-hover:rgba(83, 192, 243, 1);
    --color-tertiary-background-active:rgba(83, 192, 243, 1);
    --color-tertiary-border-active:rgba(64, 186, 242, 1);
    --color-tertiary-opacity:rgba(132, 210, 246, .25);
    --color-tertiary-gradient-lr:linear-gradient(0deg, rgba(132,210,246,1) 0%, rgba(132,210,246,0) 100%);

    --color-border-dark:rgba(127, 138, 148, 1);
    --color-border-light:rgba(179, 178, 177, 1);
  
    --color-link-light:var(--color-primary);
    --color-link-dark:var(--color-primary);
    --color-link-visited-light:var(--color-primary-background-active);
    --color-link-visited-dark:var(--color-primary-background-active);

    --color-white: #fff;
    --color-white-opacity-primary:rgba(255,255,255,0.5);
    --color-white-opacity-secondary:rgba(255,255,255,0.7);

    --color-black: #000;
	--color-black-opacity-primary:rgba(0,0,0,0.6);
    --color-black-opacity-secondary:rgba(0,0,0,0.4);
	--color-black-opacity-tertiary:rgba(0,0,0,0.8);
  
    --color-error:rgba(204, 0, 0, 1);
    --color-error-background:rgba(204, 0, 0, .05);
    --color-error-border:rgba(204, 0, 0, .75);

    --color-success:rgba(0, 122, 0, 1);
    --color-success-background:rgba(0, 122, 0, .05);
    --color-success-border:rgba(0, 122, 0, .75);
    
    --color-info:rgba(17, 17, 255, 1);
    --color-info-background:rgba(17, 17, 255, .05);
    --color-info-border:rgba(17, 17, 255, .75);
    
    --color-warning:rgba(255, 165, 0, 1);
    --color-warning-background:rgba(255, 165, 0, .05);
    --color-warning-border:rgba(255, 165, 0, .75);
    
    --color-focus-dark:var(--color-primary);
    --color-focus-light:var(--color-tertiary);
  	--color-focus:var(--color-text-dark);
  
	--box-shadow:rgb(0 0 0 / 15%) 0 var(--border-thin) 7px;
    --box-shadow-horizontal:0px var(--border-medium) calc(.25 * var(--s0)) 0 var(--color-black-opacity-secondary);

  --ratio:1.25;

	--s-6:calc(var(--s0)/var(--ratio)/var(--ratio)/var(--ratio)/var(--ratio)/var(--ratio)/var(--ratio));//4.194304
	--s-5:calc(var(--s0)/var(--ratio)/var(--ratio)/var(--ratio)/var(--ratio)/var(--ratio));//5.24288
    --s-4:calc(var(--s0)/var(--ratio)/var(--ratio)/var(--ratio)/var(--ratio));//6.5536
    --s-3:calc(var(--s0)/var(--ratio)/var(--ratio)/var(--ratio));//8.192
    --s-2:calc(var(--s0)/var(--ratio)/var(--ratio));//10.24
    --s-1:calc(var(--s0)/var(--ratio));//12.8
    --s0:1rem;//16
    --s1:calc(var(--s0)*var(--ratio));//20
    --s2:calc(var(--s0)*var(--ratio)*var(--ratio));//25
    --s3:calc(var(--s0)*var(--ratio)*var(--ratio)*var(--ratio));//31.25
    --s4:calc(var(--s0)*var(--ratio)*var(--ratio)*var(--ratio)*var(--ratio));//39.0625
	--s5:calc(var(--s0)*var(--ratio)*var(--ratio)*var(--ratio)*var(--ratio)*var(--ratio));//48.828125
	--s6:calc(var(--s0)*var(--ratio)*var(--ratio)*var(--ratio)*var(--ratio)*var(--ratio)*var(--ratio));//61.03515625

  
    --s0x2:calc(2*var(--s0));
  
    --measure-small:40ch;
    --measure:65ch;
    --measure-long:90ch;
    --measure-max:75em;

    --form-width-smallest: 20ch;
    --form-width-small: 30ch;
    --form-width: 40ch;
    --form-width-large: 50ch;
    --form-width-largest: 60ch;

    --line-height:calc(1.2*var(--ratio));
    --line-height-small: var(--ratio);
    
    --border-thinnest:calc(.0625*var(--s0));
    --border-thin:calc(.125*var(--s0));
    --border-medium:calc(.1875*var(--s0));
    --border-thick:calc(1.5*var(--s0));
    --border-radius: calc(.1875*var(--s0));
    --border-radius-inner: calc(.125*var(--s0));

	--layout-space-support: min(max(.5 * var(--s0), 2vw), var(--s0));
	--layout-space: clamp(.5 * var(--s0), 2vw, var(--s0));
	--content-space-support: min(max(.5 * var(--s0), 2vw), 1.5 * var(--s0));
	--content-space: clamp(.5 * var(--s0), 2vw, 1.5 * var(--s0));

	--font-size-small: calc(.875 * var(--s0));
  
    line-height:calc(1.2*var(--ratio));
  
	font-size: min(max(var(--font-size-small), 2.25vw), var(--s0));
	@supports (font-size: clamp(var(--font-size-small), 2.25vw, var(--s0))) {
		font-size: clamp(var(--font-size-small), 2.25vw, var(--s0));
	}
    font-family:var(--font-plain);

    a{color:var(--color-link-dark)}

    background-color:var(--color-background-light);
    color:var(--color-text-dark);
}

:focus{outline:var(--border-thin) solid var(--color-focus-dark);outline-offset:var(--border-thinnest)}

h1, .h1{

  line-height:var(--line-height-small);
  font-family:var(--font-heading);
  font-weight:var(--font-weight-normal);
  color:var(--color-text-dark-alt);
  font-size: var(--s3);
}
h2, .h2{
  line-height: var(--line-height-small);
  font-family:var(--font-heading);
  font-weight:var(--font-weight-normal);
  font-size: var(--s2);
}
h3, .h3{
  line-height: var(--line-height-small);
  font-family:var(--font-heading);
  font-weight:var(--font-weight-normal);
  font-size: var(--s1);
}
h4, .h4{
  line-height: var(--line-height-small);
  font-family:var(--font-heading);
  font-weight:var(--font-weight-normal);
  font-size: calc(1.125*var(--s0));
}
p {
  max-width:80ch;
}
`

export default ThemeStyle